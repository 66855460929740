'use client';

import { useEffect } from 'react';
import axios from 'axios';
import { Log } from '../../../lib/Logger';
import { getFlowId } from '@maverick/logger/dist/Logger';

const stackApi = axios.create({
	baseURL: '/api/stack',
});

export const Error = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) => {
	useEffect(() => {
		parseStack();
	}, [error]);

	const parseStack = async () => {
		const response = await stackApi.post('/', { stack: error.stack });
		Log.Error('AppCrash', { error: error?.message, stack: response.data });
	};

	return (
		<html>
			<head>
				<meta charSet='utf-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<title>Dine Rewards</title>
				<style
					dangerouslySetInnerHTML={{
						__html: `
                        * {
                            box-sizing: border-box;
                        }
                        body {
                            font-family: Arial, sans-serif;
                        }
                        .container {
                            margin: 32px auto 0;
                            background: #fff;
                            padding: 32px;
                            border-radius: 12px;
                            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
                            width: 400px;
                            max-width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 24px;
                        }
                        .logo {
                            display: flex;
                            justify-content: center;
                            width: 90%;
                            margin: 0 auto;
                        }
                        .logo img {
                            width: 200px;
                        }
                        .title {
                            font-size: 1.8rem;
                            margin: 32px 0 0;
                            text-align: center;
                        }
                        .text {
                            font-size: 1.4rem;
                            margin: 24px 0 0;
                            text-align: center;
                        }
                        .details {
                            margin: 24px 0 0;
                            font-size: 0.8rem;
                            opacity: 0.6;
                            text-align: center;
                        }
                        .buttons {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;
                        }
                        .buttons button {
                            width: 100%;
                            text-align: center;
                            cursor: pointer;
                            padding: 12px 0;
                            border: 2px solid #26825f;
                        }
                        .buttons button:hover {
                            opacity: 0.9;
                        }
                        .button-1 {
                            background: #26825f;
                            color: #fff;
                        }
                        .button-2 {
                            color: #26825f;
                            background: #fff;
                        }
                    `,
					}}
				/>
			</head>
			<body>
				<div className='container'>
					<div className='logo'>
						<img
							src='img/dine-rewards-logo.svg'
							id='dine-rewards-logo'
							data-testid='dine-rewards-logo'
							alt='Dine Rewards logo'
						/>
					</div>

					<div className='title'>Sorry, an expected error has occurred.</div>

					<div className='text'>
						But don't worry, we have reported it to our team and will have it fixed in no time!
					</div>

					<div className='details'>
						Details:
						<br />
						ID {getFlowId()}
					</div>

					<div className='buttons'>
						<button className='button-1' onClick={reset}>
							Try again
						</button>
						<button
							className='button-2'
							onClick={() => (window.location.href = 'https://www.dine-rewards.com')}
						>
							Go to Home
						</button>
					</div>
				</div>
			</body>
		</html>
	);
};
